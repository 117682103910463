import {
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
} from "antd";
import moment from "moment";
import { useContext } from "react";
import { DragInputList } from "~/components/shared/DragInputList";
import { GlobalContext } from "~/context/global.context";
import { ArticleTypeEnum } from "~/model";
import {
  languageOptions,
  pasteAndClearLineBreaks,
  searchFilterOptions,
} from "~/utils/helpers";

type InnerProps = {
  form: FormInstance;
  section: "card" | "publication";
};
const ArticleEditionContent = ({ form, section }: InnerProps) => {
  const { tagList, journalList } = useContext(GlobalContext);

  const parentList = Form.useWatch("medical_specialties", form);

  const handleKeywordsSplit = () => {
    const keywords = form.getFieldsValue()["keywords"];
    const newKeywords = keywords
      .join(",")
      .split(/[,;. -]/)
      .map((k: string) => k.trim());

    form.setFieldsValue({
      ...form.getFieldsValue(),
      keywords: newKeywords,
    });
  };

  const handleAuthorSplit = () => {
    const authors = form.getFieldsValue()["authors"];
    const newAuthors = authors
      .join(",")
      .replaceAll("* ", "")
      .split(/[,;]/)
      .map((k: string) => k.trim())
      .filter(
        (k: string) => k.length > 0 && k != "MD" && k != "PhD" && k != "MSc"
      );

    form.setFieldValue("authors", newAuthors);
  };

  if (section == "card")
    return (
      <>
        <Form.Item name='language' label='Language'>
          <Select placeholder='Language' options={languageOptions} />
        </Form.Item>
        <Form.Item name='publication_date' label='Publication date'>
          <DatePicker
            format='DD/MM/YYYY'
            style={{ width: "100%" }}
            placeholder='Publication date'
            disabledDate={(current) =>
              current && current > moment().endOf("day")
            }
          />
        </Form.Item>
        <Form.Item name='medical_specialties' label='Medical specialties'>
          <Select
            mode='multiple'
            placeholder='Medical specialties'
            filterOption={searchFilterOptions}
            options={tagList
              ?.filter((tag) => !Object.keys(tag).includes("parent"))
              .map((tag) => ({
                label: tag?.translations?.en,
                value: tag.uid,
              }))}
          />
        </Form.Item>
        <Form.Item name='tags' label='Tags'>
          <Select
            mode='multiple'
            placeholder='Medical specialties'
            filterOption={searchFilterOptions}
            options={tagList
              ?.filter((tag) =>
                parentList?.length
                  ? parentList.includes(tag?.parent?.uid as never)
                  : true
              )
              .map((tag) => ({
                label: `${tag?.translations?.en} (${tag.parent?.translations?.en})`,
                value: tag.uid,
              }))}
          />
        </Form.Item>

        <Form.Item name='doi' label='DOI'>
          <Input placeholder='DOI' />
        </Form.Item>

        <Form.Item name='title' label='Title'>
          <Input placeholder='Title' />
        </Form.Item>

        <DragInputList
          name='conclusion'
          label='Conclusion'
          form={form}
          type='textarea'
        />

        <Form.Item name='journal' label='Journal'>
          <Select
            placeholder='Journal'
            showSearch
            filterOption={searchFilterOptions}
            options={journalList.map((journal) => ({
              value: journal.uid,
              label: journal.name,
            }))}
          />
        </Form.Item>

        <Form.Item name='primary_author' label='Primary author'>
          <Input placeholder='Primary author' />
        </Form.Item>

        <Row gutter={8}>
          <Col flex='auto'>
            <DragInputList
              name='authors'
              form={form}
              type='input'
              label='Authors'
            />
          </Col>
          <Col>
            <Button onClick={handleAuthorSplit}>{"Split authors"}</Button>
          </Col>
        </Row>
      </>
    );

  return (
    <>
      <Form.Item name='edito' label='Edito'>
        <Input placeholder='Edito' />
      </Form.Item>
      <Form.Item name='background' label='Background'>
        <Input.TextArea
          placeholder='Background'
          rows={5}
          onPaste={pasteAndClearLineBreaks}
        />
      </Form.Item>

      <DragInputList
        name='objectives'
        label='Objectives'
        form={form}
        type='textarea'
      />

      <Form.Item name='methodology' label='Methodology'>
        <Input.TextArea
          placeholder='Methodology'
          rows={5}
          onPaste={pasteAndClearLineBreaks}
        />
      </Form.Item>

      <DragInputList
        name='results'
        form={form}
        label='Results'
        type='textarea'
      />

      <Form.Item name='limitations' label='Limitations'>
        <Input.TextArea
          placeholder='Limitations'
          rows={5}
          onPaste={pasteAndClearLineBreaks}
        />
      </Form.Item>
      <Form.Item name='disclosure' label='Disclosure'>
        <Input.TextArea
          placeholder='Disclosure'
          rows={5}
          onPaste={pasteAndClearLineBreaks}
        />
      </Form.Item>
      <Form.Item name='article_type' label='Article type'>
        <Select
          placeholder='Article type'
          options={Object.values(ArticleTypeEnum).map((value) => ({
            label: value,
            value,
          }))}
        />
      </Form.Item>
      <Form.Item label='Keywords'>
        <Row gutter={8} align='middle'>
          <Col flex='auto'>
            <Form.Item name='keywords' noStyle>
              <Select
                mode='tags'
                placeholder='Keywords'
                tokenSeparators={[",", ";", ".", "•"]}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Button onClick={handleKeywordsSplit}>{"Split Tags"}</Button>
          </Col>
        </Row>
      </Form.Item>
    </>
  );
};

export default ArticleEditionContent;
