import React, { useEffect, useRef, useState } from "react";
import { Input, Button, Form, FormInstance } from "antd";
import {
  DragOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import { pasteAndClearLineBreaks } from "~/utils/helpers";

interface IDragInputListProps {
  name: string;
  form: FormInstance;
  type: "input" | "textarea";
  label?: string;
}

export const DragInputList: React.FC<IDragInputListProps> = ({
  name,
  form,
  type,
  label,
}) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const watchFields = Form.useWatch(name, form);
  const [fields, setFields] = useState<string[] | null>(null);
  const [dragging, setDragging] = useState(false);
  const [disableDrag, setDisableDrag] = useState(true);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  const threshold = 10;
  const rowHeight = type === "textarea" ? 160 : 50;

  const handleInputChange = (value: string, index: number) => {
    if (!fields) return;

    const newFields = [...fields];
    newFields[index] = value;
    setFields(newFields);
  };

  const setInputRef = (element: HTMLInputElement | null, index: number) => {
    inputRefs.current[index] = element; // Store ref for each input
  };

  const handleAddField = () => {
    if (!fields) setFields([""]);
    else setFields([...fields, ""]);
  };

  const handleRemoveField = (field: string, index: number) => {
    if (!fields) return;

    const newFields = [...fields.filter((f, i) => i !== index && f !== field)];
    setFields(newFields);
    form.setFieldValue(name, newFields);
  };

  function handleMouseDown(e: React.MouseEvent<HTMLDivElement>) {
    setStartPosition({ x: e.clientX, y: e.clientY });
    setDragging(true);
  }

  function handleMouseMove(e: React.MouseEvent<HTMLDivElement>) {
    if (dragging) {
      const deltaY = Math.abs(e.clientY - startPosition.y);
      const deltaX = Math.abs(e.clientX - startPosition.x);

      if (deltaY > threshold || deltaX > threshold) {
        setDisableDrag(false);
        setDragging(false);
      }
    }
  }

  function handleMouseUp() {
    setDragging(false);
    setDisableDrag(true);
  }

  const handleDragChange = (
    _sourceId: string,
    sourceIndex: number,
    targetIndex: number
  ) => {
    if (sourceIndex === targetIndex || !fields) return;

    const updated = swap(fields, sourceIndex, targetIndex);

    setFields(updated);
    form.setFieldValue(name, updated);

    // Focus the input at targetIndex after drag ends
    setTimeout(() => {
      if (inputRefs.current[targetIndex]) {
        inputRefs.current[targetIndex]?.focus();
      }
    }, 0);
  };

  useEffect(() => {
    const initialValues = form.getFieldValue(name);

    if (!initialValues && !fields) setFields([]);
    else if (fields?.[fields?.length - 1] === "") return;
    else setFields(initialValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue(name), watchFields]);

  if (!fields) return null;

  return (
    <Form.Item label={label}>
      <GridContextProvider onChange={handleDragChange}>
        <GridDropZone
          id='items'
          boxesPerRow={1}
          rowHeight={rowHeight}
          style={{
            height: rowHeight * fields?.length,
          }}
          onMouseUp={handleMouseUp}
          disableDrag={disableDrag}
        >
          {fields?.map((field, index) => (
            <GridItem
              key={name + index + "drag-input-field"}
              style={{ display: "flex", gap: 8, alignItems: "center" }}
            >
              <div
                style={{
                  border: "1px solid #d9d9d9",
                  cursor: "move",
                  width: 48,
                  height: type === "textarea" ? "88%" : "63%",
                  display: "flex",
                }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
              >
                <DragOutlined
                  style={{
                    fontSize: 21,
                    justifyContent: "center",
                    margin: "auto",
                    alignItems: "center",
                  }}
                />
              </div>

              <Form.Item name={[name, index]} noStyle preserve={true}>
                {type === "textarea" ? (
                  <Input.TextArea
                    ref={(el) => setInputRef(el as HTMLInputElement, index)}
                    autoSize={{ minRows: 6, maxRows: 6 }}
                    placeholder={"Enter value"}
                    style={{ width: "100%", marginRight: 8 }}
                    onChange={(e) => handleInputChange(e.target.value, index)}
                    onPaste={pasteAndClearLineBreaks}
                  />
                ) : (
                  <Input
                    ref={(el) =>
                      setInputRef(el as unknown as HTMLInputElement, index)
                    }
                    placeholder={"Enter value"}
                    style={{ width: "80%", marginRight: 8 }}
                    onChange={(e) => handleInputChange(e.target.value, index)}
                  />
                )}
              </Form.Item>
              <Button
                shape='circle'
                icon={<MinusCircleOutlined />}
                onClick={() => handleRemoveField(field, index)}
              />
            </GridItem>
          ))}
        </GridDropZone>
      </GridContextProvider>
      <Button
        type='dashed'
        style={{ width: "60%" }}
        icon={<PlusOutlined />}
        onClick={handleAddField}
      >
        Add field
      </Button>
    </Form.Item>
  );
};
