import { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Table,
  Tag,
  notification,
} from "antd";
import { GlobalContext } from "~/context/global.context";
import { Flex, Spacer } from "./shared/global";
import { getNotificationsList, sendNotification } from "~/services";
import { INotification, SupportedLanguage } from "~/model";
import { languageOptions, searchFilterOptions } from "~/utils/helpers";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { Link } from "react-router-dom";
import moment from "moment";
import { AppstoreAddOutlined } from "@ant-design/icons";
import { SorterResult } from "antd/lib/table/interface";

const NotificationList = () => {
  const { roomList } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const [notifications, setNotifications] = useState<{
    docs: INotification[];
    meta: { total: number; offset: number; limit: number };
  } | null>(null);
  const [notificationSending, setNotificationSending] =
    useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleFetchList = async (
    offset = 0,
    limit = 20,
    sortOrder: "asc" | "desc" = "desc"
  ) => {
    try {
      const res = await getNotificationsList({
        limit,
        offset,
        sortOrder,
      });
      setNotifications(res);
    } catch (error) {
      notification.error({
        message: "Error",
        description: "An error occurred while fetching the notification",
      });
      throw error;
    }
  };

  const handleFinish = async (notificationData: {
    title: string;
    text: string;
    url: string;
    language?: SupportedLanguage;
    room?: string;
  }) => {
    try {
      setNotificationSending(true);

      await sendNotification(notificationData);

      form.resetFields();
      setNotificationSending(false);

      notification.success({
        message: "Notification sent",
        description: "The notification was sent successfully",
      });
    } catch (error) {
      setNotificationSending(false);

      notification.error({
        message: "Error",
        description:
          (error as Error)?.message ||
          "An error occurred while sending the notification",
      });
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _filters: unknown,
    sorter: SorterResult<INotification> | SorterResult<INotification>[]
  ) => {
    const _sorter = sorter as SorterResult<INotification>;
    const sortOrder = _sorter.order === "ascend" ? "asc" : "desc";

    handleFetchList(
      (pagination.current || 1) - 1,
      pagination.pageSize || 20,
      sortOrder
    );
  };

  useEffect(() => {
    handleFetchList();
  }, []);

  const columns: ColumnsType<INotification> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (title) => <span style={{ fontWeight: 600 }}>{title}</span>,
    },
    {
      title: "Read",
      dataIndex: "read",
      key: "read",
      render: (read) =>
        read ? <Tag color='green'>{"👍 Yes"}</Tag> : <Tag>{"👎 No"}</Tag>,
    },
    {
      title: "Type",
      dataIndex: ["action", "notificationType"],
      key: "notificationType",
      render: (notificationType, notif: INotification) =>
        notificationType === "ExternalLink" ? (
          <Link to={notif.action.url}>{notificationType}</Link>
        ) : (
          notificationType
        ),
    },
    {
      title: "Body",
      dataIndex: "body",
      key: "body",
    },
    {
      title: "Creation date",
      dataIndex: ["meta", "creationDate"],
      key: "title",
      width: 140,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      render: (creationDate) => moment(creationDate).format("MM/DD/YYYY"),
    },
  ];

  return (
    <div className='basic-layout'>
      <Flex justify='space-between'>
        <h1 style={{ fontWeight: 800, fontSize: "30px" }}>Push Notification</h1>
        <Button
          icon={<AppstoreAddOutlined />}
          type='primary'
          onClick={() => setShowModal(true)}
        >
          Create notification
        </Button>
      </Flex>
      <Spacer />

      <Card title='Notifications list'>
        <Table
          size='small'
          loading={!notifications}
          columns={columns}
          dataSource={notifications?.docs.map((notif) => ({
            key: notif.id,
            ...notif,
          }))}
          onChange={handleTableChange}
          pagination={{
            total: notifications?.meta.total || 0,
            pageSize: notifications?.meta.limit || 20,
            current: (notifications?.meta.offset || 0) + 1,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
          }}
        />
      </Card>

      <Modal
        open={showModal}
        okText='Send notification'
        onOk={() => form.submit()}
        onCancel={() => setShowModal(false)}
      >
        <Card title='Create a notification'>
          <Spin spinning={notificationSending}>
            <Form
              form={form}
              layout='vertical'
              name='send_notification'
              onFinish={handleFinish}
            >
              <Form.Item key='title' label='Title' name='title' required>
                <Input placeholder='Title' />
              </Form.Item>
              <Form.Item key='text' label='Text' name='text' required>
                <Input.TextArea placeholder='Notification content' />
              </Form.Item>
              <Form.Item key='url' label='URL Link' name='url' required>
                <Input placeholder='URL' />
              </Form.Item>
              <Form.Item key='language' label='Language' name='language'>
                <Select placeholder='Language' options={languageOptions} />
              </Form.Item>
              <Form.Item key='room' name='room' label='Room'>
                <Select
                  allowClear
                  showSearch
                  placeholder='Select a room'
                  options={roomList?.map((room) => ({
                    label: room.name,
                    value: room.id,
                  }))}
                  filterOption={searchFilterOptions}
                />
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </Modal>
    </div>
  );
};

export default NotificationList;
