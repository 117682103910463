/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  message,
  Modal,
  notification,
  Row,
  Select,
  Spin,
  Tabs,
  Tag,
} from "antd";

import {
  LeftOutlined,
  ExclamationCircleOutlined,
  SoundOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import {
  getArticle,
  createArticle,
  deleteArticle,
  updateArticle,
  uploadArticleImage,
  publishArticle,
  unpublishArticle,
  updateArticleImage,
  deleteArticleImage,
  getTranslatedArticleFromDoi,
  postArticleSynthetize,
  updateImageOrder,
  searchVideos,
  postAssociateVideoToArticle,
  postDissociateVideoToArticle,
  translateArticleBySlug,
  listInfographics,
  associateInfographicToArticle,
  dissociateInfographicFromArticle,
} from "../../../services";
import {
  ContentFormatsEnum,
  IArticle,
  IInfographic,
  ITag,
  IVideo,
  PermissionEnum,
  PermissionLabel,
  SupportedLanguage,
  TranslateArticleErrorType,
} from "../../../model";
import {
  ICreateArticleDto,
  IUpdateArticleDto,
} from "../../../model/dto/article-dto";
import moment from "moment";
import * as _ from "lodash";
import { GlobalContext, IGlobalContext } from "../../../context/global.context";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingLayout from "../../../components/shared/LoadingLayout";
import { checkPermission, languageOptions } from "~/utils/helpers";
import { Flex, Spacer } from "../../../components/shared/global";
import DragImageUploader from "../../../components/shared/DragImageUploader";
import { isAxiosError } from "axios";
import ApiVideoPlayer from "@api.video/react-player";
import UploadSpeechAudio from "../../../components/UploadSpeechAudio";
import RoomsAssociator from "../../../components/shared/RoomsAssociator";
import Fuse from "fuse.js";
import ArticleEditionContent from "./ArticleEditionContent";
import slugify from "slugify";

const { confirm } = Modal;

export const ArticleEdition: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { journalList, tagList, organisationList, user } = useContext(
    GlobalContext
  ) as IGlobalContext;
  const articleId = params.id;
  const search = new URLSearchParams(location.search);
  const [innerArticle, setInnerArticle] = useState<IArticle | null>(null);

  const [articleSaving, setArticleSaving] = useState<boolean>(false);
  const [articleSynthesize, setArticleSynthesize] = useState<boolean>(false);
  const [language, setLanguage] = useState<SupportedLanguage | undefined>(
    [SupportedLanguage.EN, SupportedLanguage.FR, SupportedLanguage.IT].includes(
      (search.get("language") as SupportedLanguage) || SupportedLanguage.EN
    )
      ? (search?.get("language") as SupportedLanguage)
      : SupportedLanguage.EN
  );
  const [translatedArticles, setTranslatedArticles] = useState<
    { slug: string; language: SupportedLanguage }[]
  >([]);

  // Image Drag & Drop
  const [deleteFigures, setDeleteFigures] = useState<string[]>([]);
  const [reorderFigures, setReorderFigures] = useState<any[] | null>(null);
  const [labelFigures, setLabelFigures] = useState<
    { path: string; label: string | undefined }[]
  >([]);

  // Search video & infographics
  const [searchValue, setSearchValue] = useState<
    { videos: string; infographics: string } | undefined
  >();
  // Video
  const [innerVideos, setInnerVideos] = useState<IVideo[] | undefined>();
  const [innerInfographics, setInnerInfographics] = useState<
    IInfographic[] | undefined
  >();
  const [tmpInfographics, setTmpInfographics] = useState<
    IInfographic[] | undefined
  >(); // TODO: remove with infographic search

  const [form] = Form.useForm();
  const imageFileList = Form.useWatch("figures", form);
  const { TabPane } = Tabs;

  const fetch = async () => {
    if (articleId && !innerArticle) {
      const article = await getArticle(articleId);

      if (article) {
        setLanguage(article.language);
        setInnerArticle({
          ...article,
          medical_specialties: (article.medical_specialties as ITag[]).map(
            (tag: ITag) => tag.uid
          ),
          tags: (article.tags as ITag[]).map((tag: ITag) => tag.uid),
        });
      }

      const translations = await getTranslatedArticleFromDoi(article.doi);
      if (translations) setTranslatedArticles(translations);
      // }
    }
  };

  const handleReturn = () => {
    navigate(-1);
  };

  // Gère les images relatif à l'article qu'on soit en mode création ou édition...
  const updateFigures = async (slug: string) => {
    try {
      // Upload images
      const files = form
        ?.getFieldsValue()
        ?.figures?.filter((file: any) => file.originFileObj)
        .filter(
          (file: any) =>
            !innerArticle?.images?.some((img) => img.path === file.name)
        );

      let newArticle = innerArticle;

      if (deleteFigures) {
        for await (const path of deleteFigures) {
          const { images } = await deleteArticleImage(slug, path);
          innerArticle &&
            setInnerArticle({
              ...innerArticle,
              images,
            });
        }

        setDeleteFigures([]);
      }

      if (files)
        for await (const file of files) {
          let fileLegend = null;

          if (file.originFileObj) {
            fileLegend = labelFigures.find(
              (figure) => figure.path === file.originFileObj?.name
            )?.label;

            newArticle = await uploadArticleImage(slug, file.originFileObj);
          }

          const figureId =
            newArticle?.images[newArticle?.images.length - 1]?._id;

          // On met à jour l'ordre de l'image nouvellement ajoutée
          if (figureId && newArticle) {
            const order =
              reorderFigures?.find(
                (figure) => figure.name === file.originFileObj?.name
              )?.order ?? newArticle?.images.length - 1;

            await updateImageOrder(figureId, order);

            setReorderFigures(
              (prev) =>
                prev &&
                prev?.filter(
                  (figure) => figure.name !== file.originFileObj?.name
                )
            );

            setLabelFigures([]);
            setDeleteFigures([]);
            imageFileList;
            form.setFieldValue("figures", []);
          }

          // On met à jour la légende de l'image nouvellement ajoutée
          if (fileLegend && figureId) {
            await updateArticleImage(figureId, {
              legend: fileLegend,
            });
          }

          setLabelFigures((prev) =>
            prev.filter((figure) => figure.path !== file.originFileObj.name)
          );
        }

      if (labelFigures) {
        for await (const labelFig of labelFigures)
          if (labelFig.label && !deleteFigures.includes(labelFig.path)) {
            const figureId = newArticle?.images?.find(
              (figure) => figure.path === labelFig.path
            )?._id;
            if (figureId)
              await updateArticleImage(figureId, {
                legend: labelFig.label,
              });
          }
      }

      if (reorderFigures) {
        for await (const figure of reorderFigures) {
          const figureId = newArticle?.images?.find(
            (el) => el.path === figure.path || el.path === figure.name
          )?._id;

          if (figureId) await updateImageOrder(figureId, figure.order);
        }
      }

      // setTimeout(async () => {
      //   if (!articleId) return;
      //   console.log("slug:", articleId);
      //   const article = await getArticle(articleId);
      //   innerArticle &&
      //     setInnerArticle({ ...innerArticle, images: article.images });
      // }, 1000);
    } catch (error) {
      console.error("updateFigures", error);
      throw error;
    }
  };

  const openNotificationSuccess = () => {
    notification["success"]({
      message: "Article saved",
      description: "The article was succesfully saved.",
    });
  };

  const openNotificationError = (description: string) => {
    notification["error"]({
      message: "Article saving",
      description,
    });
  };

  const handleEdition = async (
    values: ICreateArticleDto | IUpdateArticleDto
  ): Promise<any> => {
    Object.keys(values).map(async (key: string) => {
      if (key === "journal" && values.journal) {
        if (journalList.length) {
          const journal = _.find(journalList, { name: (values as any)[key] });
          if (journal) {
            return (values[key] = journal.uid);
          }
        }
      }
      if (key === "owner" && values.owner) {
        const organisation = organisationList.find(
          (org) => org.name === values.owner
        );

        if (organisation) {
          return (values[key] = organisation._id);
        }
      }
      if ((values as any)[key] === "yes") {
        return ((values as any)[key] = true);
      }
      if ((values as any)[key] === "no") {
        return ((values as any)[key] = false);
      }
      if (key !== "isPublic" && !(values as any)[key]) {
        delete (values as any)[key];
      }
    });

    return values as any;
  };

  const handleSaving = async (
    values: ICreateArticleDto | IUpdateArticleDto
  ) => {
    setArticleSaving(true);
    const newValues = await handleEdition(values);

    if (newValues.owner === undefined) newValues.owner = null;

    try {
      if (articleId) {
        await updateFigures(articleId);

        await new Promise((resolve) => setTimeout(resolve, 1000));

        const updatedArticle = await updateArticle(
          articleId,
          newValues as IUpdateArticleDto
        );

        setInnerArticle({
          ...updatedArticle,
          medical_specialties: (
            updatedArticle.medical_specialties as ITag[]
          ).map((tag: ITag) => tag.uid),
          tags: (updatedArticle.tags as ITag[]).map((tag: ITag) => tag.uid),
        });
        setFormFieldsValues(updatedArticle);
        setLanguage(updatedArticle.language);
        navigate(`/content-management/article/${updatedArticle.slug}`);
      } else {
        const createdArticle = await createArticle(
          newValues as ICreateArticleDto
        );
        if (createdArticle) {
          navigate(`/content-management/article/${createdArticle.slug}`, {
            replace: true,
          });
        }
      }
      openNotificationSuccess();
      setArticleSaving(false);
    } catch (error: any) {
      openNotificationError(error.response.data.message);
      setArticleSaving(false);
    }
  };

  const handleSaveFailed = ({ errorFields }: any) => {
    errorFields.forEach((error: any) => {
      message.error(error.errors[0]);
    });
  };

  const handleDeletion = async () => {
    if (articleId)
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <p>Are you sure to delete this article?</p>,
        async onOk() {
          const deletedArticle = await deleteArticle(articleId);
          if (deletedArticle) {
            navigate("/content-management/article/");
          }
        },
        onCancel() {},
      });
  };

  const convertBoolean = (value: boolean) => {
    return value ? "yes" : "no";
  };

  const togglePublish = async () => {
    if (innerArticle) {
      setArticleSaving(true);

      innerArticle?.meta?.status === "draft"
        ? await publishArticle(innerArticle._id)
        : await unpublishArticle(innerArticle._id);

      setInnerArticle({
        ...innerArticle,
        meta: {
          ...innerArticle.meta,
          status:
            innerArticle?.meta?.status === "draft" ? "published" : "draft",
        },
      });
      setArticleSaving(false);

      notification.success({
        message: `This article has been successfuly ${
          innerArticle?.meta?.status === "draft" ? "published" : "unpublished"
        }`,
        placement: "top",
      });
    }
  };

  const handleSynthesize = async () => {
    if (!innerArticle) return;

    setArticleSynthesize(true);

    try {
      notification.info({
        message: "Article synthetize in progress...",
        description:
          "Please wait a few seconds, the article is being synthetized...",
        placement: "top",
      });
      const newArticle = await postArticleSynthetize(innerArticle._id);
      notification.success({
        message: "Article synthetized",
        description:
          "The article is being synthetized, it'll be available soon.",
        placement: "top",
      });

      setInnerArticle({
        ...innerArticle,
        speech: newArticle.speech,
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: "An error occured while synthetizing the article.",
        placement: "top",
      });
    }
    setArticleSynthesize(false);
  };

  const handleAssociateVideo = async (videoId: string) => {
    if (!innerArticle) return;

    try {
      const newArticle = await postAssociateVideoToArticle(
        innerArticle._id,
        videoId
      );

      setInnerArticle({
        ...innerArticle,
        video: newArticle.video,
      });

      notification.success({
        message: "Video associated",
        description: "The video has been associated to the article.",
        placement: "top",
      });
    } catch (error) {
      let description = "An error occured while associating the video.";
      if (isAxiosError(error))
        description = error.response?.data?.message || message;

      notification.error({
        message: "Error",
        description,
        placement: "top",
      });
    }
  };

  const handleDissociateVideo = async () => {
    if (!innerArticle) return;

    Modal.confirm({
      title: "Dissociate video",
      content: "Are you sure to dissociate this video?",
      onOk: async () => {
        try {
          const newArticle = await postDissociateVideoToArticle(
            innerArticle._id
          );

          setInnerArticle({
            ...innerArticle,
            video: newArticle.video,
          });

          notification.success({
            message: "Video dissociated",
            description: "The video has been dissociated from the article.",
            placement: "top",
          });
        } catch (error) {
          let description = "An error occured while dissociating the video.";
          if (isAxiosError(error))
            description = error.response?.data?.message || message;

          notification.error({
            message: "Error",
            description,
            placement: "top",
          });
        }
      },
    });
  };

  const handleAssociateInfographic = async (infographicId: string) => {
    if (!innerArticle) return;

    try {
      const newArticle = await associateInfographicToArticle({
        articleId: innerArticle._id,
        infographicId,
      });

      notification.success({
        message: "Infographic associated",
        description: "The infographic has been associated to the article.",
        placement: "top",
      });

      setInnerArticle({
        ...innerArticle,
        associatedContent: newArticle.associatedContent,
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: "An error occured while associating the infographic.",
        placement: "top",
      });
      throw error;
    }
  };

  const handleDissociateInfographic = async (infographicId: string) => {
    if (!innerArticle) return;

    try {
      const newArticle = await dissociateInfographicFromArticle({
        articleId: innerArticle._id,
        infographicId,
      });

      notification.success({
        message: "Infographic dissociated",
        description: "The infographic has been dissociated from the article.",
        placement: "top",
      });

      setInnerArticle({
        ...innerArticle,
        associatedContent: newArticle.associatedContent,
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: "An error occured while dissociating the infographic.",
        placement: "top",
      });
      throw error;
    }
  };

  const handleTranslateArticle = useCallback(
    async (language: SupportedLanguage | "all") => {
      if (!innerArticle) return;

      const handleTranslate = async (language: SupportedLanguage) => {
        try {
          const translatedArticle = await translateArticleBySlug(
            language,
            innerArticle.slug
          );

          if (
            (translatedArticle as TranslateArticleErrorType)?.status === "error"
          ) {
            const articleTitle = (
              translatedArticle as TranslateArticleErrorType
            ).translated_payload.title;

            notification.error({
              placement: "top",
              message: "Error",
              description: (translatedArticle as TranslateArticleErrorType)
                .message,
            });

            if (!articleTitle) return;

            notification.destroy();

            Modal.confirm({
              title: "An article may already exists with this title",
              content: (
                <div>
                  <small>Title: {articleTitle}</small>
                  <p>
                    Please check the DOI of this article and make sure it is the
                    same as the original article.
                  </p>
                </div>
              ),
              okText: "Go to article",
              onOk: () => {
                setInnerArticle(null);
                navigate(
                  `/content-management/article/${slugify(articleTitle, {
                    remove: /[*+~.()'"!:@]/g,
                    lower: true,
                    strict: true,
                  })}`,
                  {
                    replace: true,
                    state: {
                      translatedArticles,
                    },
                  }
                );
              },
            });
            return;
          }

          return { slug: (translatedArticle as IArticle).slug, language };
        } catch (error) {
          notification.error({
            placement: "top",
            message: "Error",
            description: "An error occurred while translating the article.",
          });
          throw error;
        }
      };

      Modal.confirm({
        title: "Translate article",
        content:
          "Are you sure to translate this article in " +
          (language === "all" ? "all languages" : language) +
          "?",
        onOk: async () => {
          const newTranslations: {
            slug: string;
            language: SupportedLanguage;
          }[] = [];

          if (language === "all") {
            const lngOptions = languageOptions.filter(
              (lang) =>
                lang.value !== innerArticle?.language &&
                !translatedArticles?.find((art) => art.language === lang.value)
            );

            for (const lang of lngOptions) {
              if (lang.value !== innerArticle.language) {
                const translated = await handleTranslate(lang.value);
                if (translated) newTranslations.push(translated);
              }
            }
          } else {
            const translated = await handleTranslate(language);
            if (translated) newTranslations.push(translated);
          }

          setTranslatedArticles([...translatedArticles, ...newTranslations]);

          notification.success({
            placement: "top",
            message: "Articles translation tasks created",
            description: "The articles have been sent to translation service.",
          });
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [innerArticle, translatedArticles]
  );

  const temporaryGetInfographics = async () => {
    const { docs: infographics } = await listInfographics({
      limit: 1000,
      offset: 0,
    });

    setTmpInfographics(infographics);
  };

  const setFormFieldsValues = (article: IArticle) => {
    console.log("setFormFieldsValues", article?.doi);

    form.setFieldValue("language", article.language);
    form.setFieldValue("publication_date", moment(article.publication_date));
    form.setFieldValue("doi", article.doi);
  };

  useEffect(() => {
    temporaryGetInfographics();
  }, []);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleSaving, location.key]);

  useEffect(() => {
    const handleSearchVideo = async () => {
      if (!searchValue?.videos.length) {
        setInnerVideos([]);
        return;
      }

      const { hits } = await searchVideos(searchValue.videos, {
        language,
      });

      const videos = hits.map((hit: any) => ({
        ...hit._source.view,
        id: hit._source.id,
      }));

      setInnerVideos(videos);
    };

    // TODO: implement search infographics
    const handleSearchInfographics = () => {
      if (!tmpInfographics || !searchValue?.infographics?.length) return;

      const fuse = new Fuse(tmpInfographics, {
        keys: ["title"],
        threshold: 0.3,
      });

      const results = fuse.search(searchValue?.infographics);

      setInnerInfographics(results.map((result) => result.item));
    };

    if (searchValue?.videos) handleSearchVideo();
    else if (searchValue?.infographics) handleSearchInfographics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, language]);

  if ((!innerArticle && "id"! in params) || !tagList.length)
    return <LoadingLayout />;

  const languagesToTranslate = languageOptions.filter(
    (lang) =>
      lang.value !== innerArticle?.language &&
      !translatedArticles?.find((art) => art.language === lang.value)
  );

  const associatedInfographics = innerArticle?.associatedContent?.filter(
    (content) => content.content_format === ContentFormatsEnum.INFOGRAPHIC
  );

  return (
    <Layout>
      <Layout.Content style={{ padding: "50px" }}>
        <Flex justify='space-between' align='center' gap={24}>
          <Flex gap={8}>
            <Button
              onClick={handleReturn}
              shape='circle'
              style={{ marginRight: 20 }}
            >
              <LeftOutlined />
            </Button>
            <div>
              <h1 style={{ fontWeight: 800, fontSize: "30px" }}>
                {articleId ? "Edit article" : "Create article"}
              </h1>
              <h3 style={{ marginTop: 0 }}>{innerArticle?.title}</h3>
            </div>
          </Flex>
          <Flex align='center' gap={4} justify='end'>
            <Select
              placeholder={"Translate article"}
              onChange={handleTranslateArticle}
              disabled={!Object.keys(languagesToTranslate).length}
              options={[
                {
                  label: "All languages",
                  options: [
                    {
                      label: "Translate ALL",
                      value: "all",
                    },
                  ],
                },
                {
                  label: "Language",
                  options: languagesToTranslate,
                },
              ]}
              value={null}
            />
            <Divider type='vertical' />
            <Select
              style={{ width: 120 }}
              placeholder='Translated version'
              defaultValue={articleId}
              disabled={!translatedArticles?.length}
              options={translatedArticles.map((art) => ({
                label: `Version ${art.language.toUpperCase()}`,
                value: art.slug,
                disabled: art.slug === articleId,
              }))}
              onChange={(value) => {
                setInnerArticle(null);
                navigate(`/content-management/article/${value}`, {
                  replace: true,
                  state: {
                    translatedArticles,
                  },
                });
              }}
            />
            <Divider type='vertical' />
            {articleId ? (
              <Tag
                style={{ padding: "5px 7px", fontSize: "14px" }}
                color={
                  innerArticle?.meta?.status === "draft" ? "warning" : "success"
                }
              >
                {innerArticle?.meta?.status?.toUpperCase()}
              </Tag>
            ) : null}
            <Button onClick={togglePublish}>
              {innerArticle?.meta?.status === "draft"
                ? "Publish "
                : "Unpublish"}
            </Button>
            <Divider type='vertical' />
            <Flex gap={8} justify='end'>
              <Button
                type='primary'
                className='article-form-button'
                onClick={() => form.submit()}
              >
                {articleId ? "Save article" : "Create article"}
              </Button>
              {articleId ? (
                <Button
                  danger
                  onClick={handleDeletion}
                  disabled={
                    (user || false) &&
                    !checkPermission(
                      user,
                      PermissionLabel.Content,
                      PermissionEnum.Delete
                    )
                  }
                >
                  Delete article
                </Button>
              ) : null}
            </Flex>
          </Flex>
        </Flex>
        <br />
        <div className='site-layout-content'>
          <Spin spinning={articleSaving} tip='Article saving...'>
            <Form
              form={form}
              layout='vertical'
              name='article_edition'
              fields={
                innerArticle
                  ? Object.keys(innerArticle).map((key: string) => ({
                      name: [key],
                      value:
                        key === "journal"
                          ? innerArticle.journal?.name
                          : key === "owner"
                          ? innerArticle.owner?.name
                          : key === "publication_date"
                          ? moment(
                              innerArticle.publication_date
                                ? innerArticle.publication_date
                                : moment(new Date())
                            )
                          : key === "abstract_provided"
                          ? convertBoolean(innerArticle.abstract_provided)
                          : innerArticle[key as keyof ICreateArticleDto],
                    }))
                  : [{ name: "language", value: language }]
              }
              onFinish={handleSaving}
              onFinishFailed={handleSaveFailed}
            >
              <Tabs defaultActiveKey='1'>
                <TabPane tab='Card' key='1'>
                  <ArticleEditionContent section='card' form={form} />
                </TabPane>
                <TabPane tab='Publication' key='2'>
                  <Form.Item name='figures'>
                    <DragImageUploader
                      name='file'
                      deleteFile={(imagePath) =>
                        setDeleteFigures([...deleteFigures, imagePath])
                      }
                      images={
                        innerArticle?.images && imageFileList
                          ? [
                              ...innerArticle.images.sort(
                                (a, b) => a.order - b.order
                              ),
                              ...imageFileList.map((file: any) => ({
                                name: file.name,
                                uid: file.uid,
                                legend: "",
                              })),
                            ]
                          : innerArticle?.images?.sort(
                              (a, b) => a.order - b.order
                            )
                      }
                      defaultImages={innerArticle?.images}
                      inputLegendImage={(legend, imagePath) => {
                        if (
                          !labelFigures.find(
                            (figure) => figure.path === imagePath
                          )
                        ) {
                          setLabelFigures([
                            ...labelFigures,
                            { path: imagePath, label: legend },
                          ]);
                        } else {
                          setLabelFigures(
                            labelFigures.map((figure) =>
                              figure.path === imagePath
                                ? { ...figure, label: legend }
                                : figure
                            )
                          );
                        }
                      }}
                      onOrderChange={(fileList) => {
                        setReorderFigures(fileList);
                      }}
                      onChange={(fileList) =>
                        form.setFieldValue("figures", fileList)
                      }
                    />
                  </Form.Item>
                  <Divider />
                  <ArticleEditionContent section='publication' form={form} />
                </TabPane>

                <TabPane tab='Rooms' key='3'>
                  {innerArticle ? (
                    <RoomsAssociator
                      form={form}
                      type={ContentFormatsEnum.ARTICLE}
                      isPublic={innerArticle.isPublic}
                      ownerId={innerArticle?.owner?._id}
                      contentId={innerArticle?._id}
                    />
                  ) : (
                    <Spin />
                  )}
                  <Divider />
                </TabPane>

                <TabPane tab='Videos' key='4'>
                  <Row gutter={16}>
                    <Col span={12}>
                      <h3>Associated Videos</h3>
                      <Spacer height={8} />
                      {innerArticle?.video ? (
                        <Card style={{ width: "100%" }}>
                          <ApiVideoPlayer
                            video={{
                              id: innerArticle?.video?.apiVideo?.videoId || "",
                            }}
                            style={{
                              width: "100%",
                              height: 300,
                            }}
                          />
                          <Divider />
                          <p
                            style={{ fontWeight: 600 }}
                            onClick={() => console.log(innerArticle.video)}
                          >
                            {innerArticle.video.title}
                          </p>
                          <Button
                            block
                            type='dashed'
                            icon={<MinusCircleOutlined />}
                            onClick={handleDissociateVideo}
                          >
                            Dissociate this video
                          </Button>
                        </Card>
                      ) : (
                        <div />
                      )}
                    </Col>
                    <Col span={12}>
                      <h3>Add videos to this article</h3>
                      <Spacer height={8} />
                      <Input.Search
                        placeholder='Enter the video URL'
                        onChange={(e) => {
                          e.preventDefault();
                          setSearchValue({
                            infographics: searchValue?.infographics || "",
                            videos: e.target.value,
                          });
                        }}
                      />
                      {searchValue && <Divider />}
                      <Flex flexDirection='column' gap={8}>
                        {innerVideos?.map((video) => (
                          <Card
                            key={video.id + "--card"}
                            style={{ width: "100%" }}
                          >
                            <a
                              style={{ fontWeight: 600 }}
                              onClick={() => console.log(video)}
                              href={video.apiVideo?.player}
                              target='_blank'
                            >
                              {video.title}
                            </a>
                            <Spacer height={8} />
                            <Button
                              block
                              icon={<PlusCircleOutlined />}
                              onClick={() => handleAssociateVideo(video.id)}
                            >
                              Add this video
                            </Button>
                          </Card>
                        ))}
                      </Flex>
                    </Col>
                  </Row>
                  <Divider />
                </TabPane>

                <TabPane tab='Infographics' key='5'>
                  <Row gutter={16}>
                    <Col span={12}>
                      <h3>Associated Infographics</h3>
                      <Spacer height={8} />
                      {associatedInfographics?.length ? (
                        associatedInfographics.map((info) => (
                          <Card style={{ width: "100%" }}>
                            <Divider />
                            <p style={{ fontWeight: 600 }}>{info.title}</p>
                            <Button
                              block
                              type='dashed'
                              icon={<MinusCircleOutlined />}
                              onClick={() =>
                                handleDissociateInfographic(info._id)
                              }
                            >
                              Dissociate this infographic
                            </Button>
                          </Card>
                        ))
                      ) : (
                        <div />
                      )}
                    </Col>
                    <Col span={12}>
                      <h3>Add infographics to this article</h3>
                      <Spacer height={8} />
                      <Input.Search
                        placeholder='Search infographics...'
                        onChange={(e) => {
                          e.preventDefault();
                          setSearchValue({
                            videos: searchValue?.videos || "",
                            infographics: e.target.value,
                          });
                        }}
                      />
                      {searchValue && <Divider />}
                      <Flex flexDirection='column' gap={8}>
                        {innerInfographics?.map((info) => (
                          <Card
                            key={info.id + "--card"}
                            style={{ width: "100%" }}
                          >
                            <b>{info.title}</b>
                            <Spacer height={8} />
                            <Button
                              block
                              icon={<PlusCircleOutlined />}
                              onClick={() =>
                                handleAssociateInfographic(info.id)
                              }
                            >
                              Add this infographic
                            </Button>
                          </Card>
                        ))}
                      </Flex>
                    </Col>
                  </Row>
                  <Divider />
                </TabPane>

                <TabPane tab='Audio' key='6'>
                  <Flex align='center' gap={8}>
                    <Button
                      disabled={!!innerArticle?.speech?.url}
                      onClick={handleSynthesize}
                      loading={articleSynthesize}
                      icon={<SoundOutlined />}
                    >
                      {articleSynthesize
                        ? "In progress..."
                        : "Get Audio (synthesize)"}
                    </Button>
                    <Divider type='vertical' />

                    <UploadSpeechAudio
                      articleId={innerArticle?._id}
                      callback={(article: IArticle) => {
                        setInnerArticle({
                          ...innerArticle,
                          speech: null,
                        } as unknown as IArticle);

                        setTimeout(() => {
                          innerArticle &&
                            setInnerArticle({
                              ...innerArticle,
                              speech: article.speech,
                            });
                        }, 1000);
                      }}
                    />
                  </Flex>
                  <Divider />
                  {!!innerArticle?.speech?.url && (
                    <audio controls>
                      <source
                        src={innerArticle?.speech?.url}
                        type='audio/mpeg'
                      />
                      Your browser does not support the audio element.
                    </audio>
                  )}
                  {innerArticle?.speech?.url && (
                    <p>
                      <b>{"Audio name: "}</b>
                      {innerArticle?.speech?.path}
                    </p>
                  )}
                </TabPane>
              </Tabs>
            </Form>
          </Spin>
        </div>
      </Layout.Content>
    </Layout>
  );
};
